<script setup lang="ts">

</script>

<template>
	<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0.666667 0.230469C0.829956 0.23049 0.987558 0.290439 1.10958 0.398945C1.2316 0.507452 1.30956 0.656968 1.32867 0.819135L1.33333 0.897135V5.5638H6V2.23047C6.00002 2.06718 6.05997 1.90958 6.16848 1.78755C6.27698 1.66553 6.4265 1.58757 6.58867 1.56847L6.66667 1.5638H11.3333C11.8435 1.56377 12.3343 1.75869 12.7055 2.10866C13.0767 2.45863 13.3001 2.93721 13.33 3.44647L13.3333 3.5638V8.89714C13.3331 9.06706 13.2681 9.23049 13.1514 9.35405C13.0348 9.4776 12.8754 9.55196 12.7057 9.56192C12.5361 9.57187 12.3691 9.51669 12.2388 9.40763C12.1085 9.29857 12.0247 9.14387 12.0047 8.97514L12 8.89714V6.89714H1.33333V8.89714C1.33314 9.06706 1.26808 9.23049 1.15143 9.35405C1.03479 9.4776 0.875365 9.55196 0.705737 9.56192C0.536109 9.57187 0.36908 9.51669 0.238778 9.40763C0.108476 9.29857 0.0247357 9.14387 0.00466665 8.97514L0 8.89714V0.897135C0 0.720324 0.0702379 0.550755 0.195262 0.425731C0.320286 0.300707 0.489856 0.230469 0.666667 0.230469ZM3.66667 1.5638C4.10869 1.5638 4.53262 1.7394 4.84518 2.05196C5.15774 2.36452 5.33333 2.78844 5.33333 3.23047C5.33333 3.6725 5.15774 4.09642 4.84518 4.40898C4.53262 4.72154 4.10869 4.89714 3.66667 4.89714C3.22464 4.89714 2.80072 4.72154 2.48816 4.40898C2.17559 4.09642 2 3.6725 2 3.23047C2 2.78844 2.17559 2.36452 2.48816 2.05196C2.80072 1.7394 3.22464 1.5638 3.66667 1.5638Z" fill="#626262"/>
	</svg>
</template>

<style scoped lang="scss">

</style>