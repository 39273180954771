<script setup lang="ts">

</script>

<template>
	<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.32134 6.49844C8.32134 6.65754 8.25809 6.81019 8.14559 6.92269C8.03309 7.03524 7.88044 7.09844 7.72134 7.09844C7.56219 7.09844 7.40959 7.03524 7.29704 6.92269C7.18454 6.81019 7.12134 6.65754 7.12134 6.49844C7.12134 6.33929 7.18454 6.18669 7.29704 6.07419C7.40959 5.96164 7.56219 5.89844 7.72134 5.89844C7.88044 5.89844 8.03309 5.96164 8.14559 6.07419C8.25809 6.18669 8.32134 6.33929 8.32134 6.49844Z" fill="#2A2536"/>
		<path d="M6.01574 0.110482C5.95739 0.0979516 5.89699 0.0986316 5.83894 0.112472C5.78089 0.126317 5.72669 0.152967 5.68029 0.190477C5.63389 0.227982 5.59644 0.275397 5.57074 0.329247C5.54504 0.383097 5.53169 0.442012 5.53174 0.501682V12.5017C5.53169 12.5613 5.54504 12.6203 5.57074 12.6741C5.59644 12.728 5.63389 12.7754 5.68029 12.8129C5.72669 12.8504 5.78089 12.877 5.83894 12.8909C5.89699 12.9047 5.95739 12.9054 6.01574 12.8929L11.6157 11.6921C11.7052 11.6727 11.7853 11.6232 11.8426 11.5518C11.9 11.4805 11.9312 11.3916 11.9309 11.3001V1.70088C11.9308 1.6095 11.8993 1.52093 11.8418 1.4499C11.7843 1.37888 11.7043 1.32967 11.6149 1.31048L6.01494 0.111282L6.01574 0.110482ZM6.33174 12.0065V0.996882L11.1317 2.02488V10.9785L6.33174 12.0073V12.0065Z" fill="#2A2536"/>
		<path d="M4.7333 12.0983V11.2983H1.53168V1.70234H4.7333V0.902344H1.13168C1.0256 0.902344 0.92386 0.944489 0.848845 1.0195C0.77383 1.09452 0.731689 1.19626 0.731689 1.30234V11.6983C0.731689 11.8044 0.77383 11.9062 0.848845 11.9812C0.92386 12.0562 1.0256 12.0983 1.13168 12.0983H4.7333Z" fill="#2A2536"/>
	</svg>

</template>

<style scoped lang="scss">

</style>